import * as React from "react"
import { Link } from "gatsby"
import {GlobalStyle} from '../ui/global';

const NotFoundPage = () => {
  return (
    <>
      <GlobalStyle/>
      <main>
        <title>404: Not found</title>
        <Link to="/">На главную</Link>
      </main>
    </>
  )
}

export default NotFoundPage
